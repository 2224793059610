import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Radius is the blunt part of the corner of a component that affects the shape and visibility of the content inside. This is the variant of radius we use globally for property the component. You can chek out the guideline for more detail description.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { legionTheme } from "@legion-ui/core";
`}</code></pre>
    <div className="divi" />
    <h2>{`Preview`}</h2>
    <p>{`Please note that each value of the radius property is determined by the token from each theme.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shape`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Token Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Rounded`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pixels`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius1" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.125 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius2" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.25 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius3" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.375 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius4" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.5 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius5" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.75 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius6" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius7" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.5 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius8" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`There are two ways on how you can use the `}<inlineCode parentName="p">{`radius`}</inlineCode>{` toolkit. First by using Legion V2’s components and second by using HTML elements.`}</p>
    <h3>{`Component`}</h3>
    <p>{`For Legion V2 usage, please refer to each component’s documentation page for more information.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box radius="radius1">...</Box>
  <Box radius="radius2">...</Box>
  <Box radius="radius3">...</Box>
  <Box radius="radius4">...</Box>
  <Box radius="radius5">...</Box>
  <Box radius="radius6">...</Box>
  <Box radius="radius7">...</Box>
  <Box radius="radius8">...</Box>
`}</code></pre>
    <h3>{`HTML Elements`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <div style={{ borderRadius: \`\${legionTheme.tokens.radius1}px\` }}>...</div>
  <div style={{ borderRadius: \`\${legionTheme.tokens.radius2}px\` }}>...</div>
  <div style={{ borderRadius: \`\${legionTheme.tokens.radius3}px\` }}>...</div>
  <div style={{ borderRadius: \`\${legionTheme.tokens.radius4}px\` }}>...</div>
  <div style={{ borderRadius: \`\${legionTheme.tokens.radius5}px\` }}>...</div>
  <div style={{ borderRadius: \`\${legionTheme.tokens.radius6}px\` }}>...</div>
  <div style={{ borderRadius: \`\${legionTheme.tokens.radius7}px\` }}>...</div>
  <div style={{ borderRadius: \`\${legionTheme.tokens.radius8}px\` }}>...</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      